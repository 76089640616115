import Routes from "./Routes";
import Navbar from '../src/components/Navbar';


function App() {
  
  return (
  
    <div className="App">
      {/* <Navbar/> */}
      <Routes />
    </div>
    
  );
  
}

export default App;
